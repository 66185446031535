import ethicalhackingclassesshackingtruth from "../../src/MyComponents/images/ethicalhackingclassesshackingtruth.png";
import penetrationtestinghackingtruth from "../../src/MyComponents/images/penetrationtestinghackingtruth.png";
import webdevelopmenthackingtruth from "../../src/MyComponents/images/webdevelopmenthackingtruth.png";
import seminarhackingtruth from "../../src/MyComponents/images/seminarhackingtruth.png";
import passiveincomehackingtruth from "../../src/MyComponents/images/passiveincomehackingtruth.png";
import progesshackingtruth from "../../src/MyComponents/images/progesshackingtruth.png";


const Servicedata = [
    {
        imgsrc: ethicalhackingclassesshackingtruth,
        title: "Ethical Hacking Classes",
        paragraph: "In computer networking, Hacking is any technical effort to manipulate behaviour of network Hacking is historically referred to constructive, clever technical work that was not necessarily related to computer systems.",
    },
    {
        imgsrc: penetrationtestinghackingtruth,
        title: "Web App Penetration Testing",
        paragraph: "A penetration test, also known as a pen test, is a simulated cyber attack against your computer system to check for exploitable vulnerabilities. In the context of web application security, penetration testing is commonly used to augment a web application firewall (WAF).",
    },
    {
        imgsrc: webdevelopmenthackingtruth,
        title: "Web Development",
        paragraph: "Web development is the work involved in developing a website for the Internet or an intranet. Web development can range from developing a simple single static page of plain text to complex web applications, electronic businesses, and social network services.",
    },
    {
        imgsrc: seminarhackingtruth,
        title: "Seminar & Workshop",
        paragraph: "Cyber security is the most concerning matter as cyber threats and attacks are overgrowing. Attackers are now using more sophisticated techniques to target the systems. It manages the set of techniques used to save the integrity of networks, programs, and data from unauthorized access.",
    },
    {
        imgsrc: passiveincomehackingtruth,
        title: "Passive Income & Technical Support",
        paragraph: "Earning a full income as a blogger or Youtuber are more realistic than you might think. Finance blog Millennial Money reports that after just 2 years of building traffic and subscribers, bloggers can make upwards of $100,000 annually. Even within just the first year, bloggers can easily make an extra $500-$2,000 per month.",
    },
    {
        imgsrc: progesshackingtruth,
        title: "Progress and Accordians",
        paragraph: "Stop learning then stop winning, so I try to take myself to a new level every single day and I must learn something new every single day so that I can update myself well. To move forward in one's work or activity We're not finished yet, but we're making progress.",
    },
    
];

export default Servicedata;