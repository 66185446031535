//import React from "react";
import React, { useState } from 'react';



const Contact = () => {

    const [data, setData] = useState({
        fullname: "",
        phone:  "",
        email: "",
        msg: "",

    });

     const InputEvent = (event) =>{

        const {name, value} = event.target;
         
        setData((preVal) => {
            return {

                ...preVal, 
                  [name] : value,
                  
            };
        }); 
    
    };




    
const formSubmit = (e) => {
         
      e.preventDefault();
      alert(`
      My name is ${data.fullname}, My Mobile number is ${data.phone}, My Email is ${data.email}, Here is what i want to say ${data.msg}`
       
      );
    };

  return (
    <>
      <div className="my-5">
        <h1 className="text-center">Contact US </h1>
      </div>
      <div className="container contact_div">
        <div className="row">
          <div className="col-md-6 col-10 mx-auto">
            <form onSubmit={formSubmit}>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  FullName
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  name="fullname"
                  value={data.fullname}
                  onChange={InputEvent}
                  placeholder="Enter your name"
                />
              </div>

             
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Phone With Country Code
                </label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleFormControlInput1"
                  name="phone"
                  value={data.phone}
                  onChange={InputEvent}
                  placeholder="Phone Number With CC"
                  
                />
              </div>

              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  name="email"
                  value={data.email}
                  onChange={InputEvent}
                  placeholder="name@example.com"
                />
              </div>




              <div class="mb-3">
                <label htmlFor="exampleFormControlTextarea1" class="form-label">
                  Message
                </label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  name="msg"
                value={data.msg}
                onChange={InputEvent}
                ></textarea>
                
              </div>

              <div class="col-12">
    <button class="btn btn-outline-primary" type="submit">Submit form</button>
  </div>
            </form>
          </div>
        </div>
      </div>
    </>

    //<h1>Welcome To Contact Page</h1>
  );
};

export default Contact;
