import React from "react";
//import '../index.css';
import web from "../../src/MyComponents/images/hacker.png";
import { NavLink } from "react-router-dom";



const Common = (props)=> {
  return (
    <>		

    <section id="header" className="d-flex align-items-center container-fluid">
    
    <div className="container-fluid ">
        <div className="row">
          <div className="col-10 mx-auto">
           <div className="row">
            <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
              <h1>{props.name} 
              
               <strong className="brand-name"> {props.othername}</strong></h1>
               <h2 className="my-3">{props.para}</h2>
                <div className="mt-3"> 
                <NavLink to={props.visit} className="btn-get-started"> {props.btname} </NavLink>
                
                </div>  
            </div>

            <div className="col-lg-5 order-1 order-lg-2 header-img ">
             <img src={props.imgsrc} className="img-fluid animated" alt="hackingtruth"  />
            </div>
            </div>


          </div>
        </div>
     </div>
     
    
    </section>
      
	</>

    //<h1>Welcome To Home Page</h1>
  );
}

export default Common;
